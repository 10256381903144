import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faListCheck} from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "../../contexts/auth"
import React, { useEffect, useState, useCallback } from "react"
import { verifyAccess } from "../../utils/admin.utils"
import {getAudit} from "../../api/administration";
import AccessDenied from "../../components/access-denied/access-denied";
import LoadPanel from "devextreme-react/load-panel";
import DataGrid, {Column, Pager, Paging, FilterRow, HeaderFilter, SearchPanel,Grouping,
    GroupPanel,Scrolling,Export, Selection,} from "devextreme-react/data-grid";
import DateBox from 'devextreme-react/date-box';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

const AuditPage = () => {

    const { selectedDog } = useAuth()
    const [visiblePage, setVisiblePage] = useState(true)
    const [loading, setLoading] = useState(false)
    const [audit, setAudit] = useState([])

    const msInDay = 1000 * 60 * 60 * 24;
    const now = new Date();
    const defaultStart = new Date(now.getTime() - msInDay * 3)
    const defaultEnd = new Date(now.getTime() + msInDay * 3)
    const [periodStart, setPeriodStart] = useState(defaultStart)
    const [periodEnd, setPeriodEnd] = useState(defaultEnd)
    // const [count, setCount] = useState(0)


    useEffect(() => {
        const pageRoles = [1]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            const resAudit = await getAudit("",periodStart, periodEnd)
            if (resAudit.isOk) {
                setAudit(resAudit.data)
                // setCount(resAudit.data.count)
            }
        })()
    }, [selectedDog,periodStart,periodEnd])

    const calendarOptions = {
        // applyValueMode: "useButtons",
        // showDropDownButton:true,
    }

    const onStartPickerChanged = useCallback((e) => {
        setPeriodStart(e.value);
    }, []);

    const onEndPickerChanged = useCallback((e) => {
        if (e) setPeriodEnd(e);
    },[]);

    const onExporting = (e) => {
        const workbook = new Workbook();
        let period = `${periodStart.toLocaleDateString('ru-ru', { year:"numeric", month:"short", day:"numeric"}) } - ${periodEnd.toLocaleDateString('ru-ru', { year:"numeric", month:"short", day:"numeric"})}`
        const worksheet = workbook.addWorksheet(`Main sheet ${period}`);

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `DataGrid ${period}.xlsx`);
            });
        });
    };

    return (
        <>{visiblePage ?
        <div className={'content-block dx-card responsive-paddings'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faListCheck} />
            <h3><strong>Аудит</strong></h3>
            <div className="row">
                <div className={'col-sm-4 col-md-3 col-lg-2'} style={{minWidth: '200px'}}>
                    <DateBox labelMode={'floating'}
                             id={'db1'}
                             label={'Период начала:'}
                             calendarOptions={calendarOptions}
                             value={periodStart}
                             onValueChanged={onStartPickerChanged} /></div>
                <div className={'col-sm-4 col-md-3 col-lg-2 mt20xs'}
                     style={{minWidth: '200px'}}>
                    <DateBox labelMode={'floating'}
                             id={'db2'}
                             label={'Период окончания:'}
                             calendarOptions={calendarOptions}
                             value={periodEnd}
                             onValueChange={onEndPickerChanged} /></div>
            </div>
            <div style={{textAlign: 'justify'}}>

                <DataGrid
                    height={660}
                    style={{margin: '20px 0px 20px 0px'}}
                    keyExpr="id"
                    columnHidingEnabled={true}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    noDataText={`За выбранный период записи отсутствуют`}
                    dataSource={audit}
                    onExporting={onExporting}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Selection mode="multiple" />
                    <Scrolling mode="virtual" />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <FilterRow
                        visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel
                        visible={true}
                        width={240}
                        placeholder="Поиск..." />
                    <Paging defaultPageSize={10} />
                    <Pager
                        visible={true}
                        allowedPageSizes={[5, 10, 20, 'all']}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                    <Column
                        type="selection"
                        width="50"
                    />
                    <Column dataField="user_id" width="60" caption="ID" dataType="string"  allowEditing={false}/>
                    <Column dataField="fio" width="220" caption="ФИО" dataType="string"  allowEditing={false}/>
                    <Column dataField="user_name" width="230" caption="user_name" dataType="string"  allowEditing={false}/>
                    <Column dataField="dat" width="200" caption="Дата" dataType="datetime"  allowEditing={false}/>
                    <Column dataField="entity" caption="entity" width="230" allowEditing={false}/>
                    <Column dataField="terminal" width="150"  caption="terminal" dataType="string"  allowEditing={false}/>
                    <Column dataField="action" width="70" caption="action"  allowEditing={false}/>
                    <Column dataField="duration" width="60" caption="duration" allowEditing={false}/>
                </DataGrid>
            </div>
            <LoadPanel visible={loading} />
        </div>
        : <AccessDenied/>}</>
    )
}

export default AuditPage
