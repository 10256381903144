import request from './api'

export async function getUsersOfDogovor(dogovor) {
    try {
        const res = await request('/api/administration/users','POST',{dogovor: dogovor})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getUsersOfDogovorWithAuth(dogovor) {
    try {
        const res = await request('/api/administration/users-with-auth','POST',{dogovor: dogovor})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getAuthorities() {
    try {
        const res = await request('/api/administration/auths','POST',null)
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getAudit(username, d1, d2) {
    try {
        const res = await request('/api/administration/get-audit','POST',{username: username, d1:d1, d2:d2})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function checkEmail(email, dogovor) {
    try {
        const res = await request('/api/administration/check-email','POST',{email: email, dogovor: dogovor})
        return {
            isOk: true,
            data: res
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function addToDog(idUser, dogovor, auths) {
    try {
        const res = await request('/api/administration/add-to-dog','PUT',{id: idUser, dogovor: dogovor, auths: auths})
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function registry(user, dogovor, auths) {
    try {
        const res = await request('/api/administration/registry','PUT',{user: user, dogovor: dogovor, auths: auths})
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function deleteUserDog(userDog) {
    try {
        const res = await request('/api/administration/remove','DELETE',{user_dog: userDog})
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function editRoles(userDog, roles) {
    try {
        const res = await request('/api/administration/edit-roles','PUT',{user_dog: userDog, roles: roles})
        return {
            isOk: true,
            message: res.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}
