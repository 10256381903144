import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import routes from './app-routes'
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts'
//import { Footer } from './components'
import { useLocation } from 'react-router-dom'
import { RequestPopup, SelectAgrDogovor, SelectDogovor } from './components'
import { useAuth } from './contexts/auth'
const Content = () => {

  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState()
  const { countDogovors, dogovors, agrDogovors } = useAuth()

  var filterRoutes = routes

  if (countDogovors === 0 && dogovors.length === 0) {
    filterRoutes = filterRoutes.filter(item => item.all_users_visible === true)
  }

  useEffect(() => {
    //определяем активную страницу
    const newPath = location.pathname
    const activeRoute = routes.find(route => route.path === newPath)
    setActiveRoute(activeRoute)
    document.title = activeRoute ? "Инфоэнерго - " + activeRoute.title : 'ЛКК ЮЛ'
  }, [location])

  return (
    <SideNavBarLayout title={activeRoute ? activeRoute.title : 'ЛКК ЮЛ'}>
      <SelectDogovor visible={activeRoute ? activeRoute.sd_visible : false} />
      <SelectAgrDogovor visible={activeRoute ? (activeRoute.sd_agr_visible && agrDogovors.length > 0) : false} />
      <RequestPopup />
      <Switch>
        {filterRoutes.map(({ path, component }) => (

          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to={'/home'} />
      </Switch>
      {/*<Footer>*/}
      {/*  <div className='mt20'>© ООО «ЕЭС-Гарант» - энергосервисная компания №1. *По данным Всероссийского рейтинга РБК</div><br/>*/}
      {/*    <div className='mt20'>© ООО «ЕЭС-Гарант» - энергосервисная компания №1. *По данным Всероссийского рейтинга РБК</div><br/>*/}
      {/*    <div className='mt20'>© ООО «ЕЭС-Гарант» - энергосервисная компания №1. *По данным Всероссийского рейтинга РБК</div><br/>*/}
      {/*    <div className='mt20'>© ООО «ЕЭС-Гарант» - энергосервисная компания №1. *По данным Всероссийского рейтинга РБК</div><br/>*/}
      {/*    <div className='mt20'>© ООО «ЕЭС-Гарант» - энергосервисная компания №1. *По данным Всероссийского рейтинга РБК</div><br/>*/}
      {/*</Footer>*/}
    </SideNavBarLayout>
  );
}

export default Content
