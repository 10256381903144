import request from './api'

export async function getUserRequests() {
    try {
        const data = await request('/api/request/user-req','GET',null)
        if (data) {// && data[0].type
            return {
                isOk: true,
                data: data
            }
        } else return {
            isOk: true,
            data: []
        }

    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getRequestTypes() {
    try {
        const data = await request('/api/request/type', 'GET', null)
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendUserRequest(formData){
    try {
        const data = await request('/api/request/user-req', 'PUT', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getAttachment(id) {
    try {
        const attach = await request('/api/request/attachment', 'POST', { id: id })//request-attach
        return {
            isOk: true,
            data: attach
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function getCountNotReaded() {
    try {
        const data = await request('/api/request/not-readed', 'POST', null)
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function updateReaded(idReq, isReaded) {
    try {
        const data = await request('/api/request/update-readed', 'POST', {id_req: idReq,is_read: isReaded})
        return {
            isOk: true,
            data: data
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}

export async function sendHelpRequest(formData){
    try {
        const data = await request('/api/request/help-req', 'POST', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}
export async function sendLettersMailRequest(formData){
    try {
        const data = await request('/api/request/letters-mail-req', 'POST', formData, 'multipart')
        return {
            isOk: true,
            message: data.message
        }
    } catch (e) {
        return {
            isOk: false,
            message: e.message
        }
    }
}
