export const tabs = [
  {
    text: 'Показания (рассчитанные)',
    content: 'RegReads'
  },
  {
    text: 'Показания (переданные)',
    content: 'RegReadsLkk'
  },
  {
    text: 'Профиль (рассчитанный)',
    content: 'OutVals'
  },
  {
    text: 'Профиль (переданный)',
    content: 'OutValsLkk'
  }
]

