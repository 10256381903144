import Chart, {
    CommonSeriesSettings,
    Export,
    Legend,
    LoadingIndicator,
    Point,
    Series,
    Size,
    Title,
    Tooltip,
    ValueAxis
} from 'devextreme-react/chart'
import DateBox from "devextreme-react/date-box"
import {Popup} from "devextreme-react"
import {useCallback, useEffect, useState} from 'react'
import {getEnergosferaCounter, getFileEnergosferaCounter} from '../../api/point'
import ScrollView from "devextreme-react/scroll-view";
import { Button } from 'devextreme-react/button';
import {PopupTitleWithIcon} from "../popup-title-with-icon/popup-title-with-icon";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import notify from 'devextreme/ui/notify'
import LoadPanel from "devextreme-react/load-panel";
import './energosfera-chart.scss'
import {faChartLine, faChartColumn} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SelectBox} from "devextreme-react/select-box";
import {Template} from "devextreme-react/core/template";


const EnergosferaChart = (props) => {

    //const data = props.data new name commit
    const visible = props.visible
    const point = props.point
    const [data, setData] = useState([])
    const [source, setSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [period1, setPeriod1] = useState(props.period1)
    const [period2, setPeriod2] = useState(props.period2)
    const [title, setTitle] = useState('')
    const [loadingFile, setLoadingFile] = useState(false)
    const [chbBarLine, setChbBarLine] = useState('spline')
    const dsBarSpline = [{id:0,type:'spline',name:'Линия', IconSrc:faChartLine},{id:1,type:'bar',name:'Столбцы', IconSrc:faChartColumn}]
    const [selectedItem, setSelectedItem] = useState(dsBarSpline[0]);

    const onHiddenPopup = () => {
        props.onClosed()
    }

    const calendarOptions = {
        applyValueMode: "useButtons",
        showDropDownButton: true,
        maxZoomLevel: 'year',
        minZoomLevel: 'month',
    }

    useEffect(() => {
        (async function () {
            await refreshClick()
            //console.log("useEffect");
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, point])

    function getPointsSource(mas) {
        let res = []
        let item = {}
        for (let i = 0; i < mas.length; i++) {
            item = {
                value: mas[i].name,
                name: mas[i].name
            }
            if (!res.find(el => el.value === mas[i].name)) res.push(item)
        }
        //console.log(res)
        return res
    }

    async function refreshClick(){
        if (visible && point) {
            setLoading(true)
            const res = await getEnergosferaCounter(point, period1, period2)
            if (res.data) {
                setSource(getPointsSource(res.data.reads))
                setData(res.data.reads)
                setTitle(res.data.title)
            }
            setLoading(false)
        } else {
            setData([])
            setSource([])
        }
    }

    async function loadFileEnergosfera() {
        setLoadingFile(true)
        const res = await getFileEnergosferaCounter(data,point)
        if (res.isOk) {
            const file = res.data.data
            const filename = res.data.filename
            var fileDownload = require('js-file-download')
            fileDownload(Buffer.from(file), filename)
        } else {
            notify(res.message, 'error', 5000)
        }
        setLoadingFile(false)
    }

    const renderConditionalIcon = useCallback(
        () => <ConditionalIcon value={selectedItem} />,
        [selectedItem],
    );
    const selectionChanged = useCallback((event) => {
        setSelectedItem(event.selectedItem);
        setChbBarLine(event.selectedItem.type)
    }, []);

    return (
        <Popup
            id="popup-energo"
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={visible}
            height='auto'
            maxHeight={'90%'}
            maxWidth="90%"
            onHiding={onHiddenPopup}
            titleRender={()=>PopupTitleWithIcon(faChartBar, "Текущий профиль потребления по ТУ (online)",onHiddenPopup)}
        >
            <ScrollView width='100%' height='100%'>
                <div>
                    <div className="row mb-4">
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <DateBox
                                openOnFieldClick={true}
                                calendarOptions={calendarOptions}
                                defaultValue={period1}
                                value={period1}
                                max={period2}
                                displayFormat='dd.MM.yyyy'
                                useMaskBehavior={true}
                                onValueChanged={(e) => setPeriod1(e.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 mt20xs">
                            <DateBox
                                openOnFieldClick={true}
                                calendarOptions={calendarOptions}
                                defaultValue={period2}
                                value={period2}
                                min={period1}
                                displayFormat='dd.MM.yyyy'
                                useMaskBehavior={true}
                                onValueChanged={(e) => setPeriod2(e.value)}
                            />
                        </div>
                        <div className={`col-lg-2 col-md-4 col-sm-4 mt20xs`}>
                            <SelectBox
                                dataSource={dsBarSpline}
                                defaultValue={dsBarSpline[0].id}
                                selectedItem={selectedItem}
                                displayExpr="name"
                                valueExpr="id"
                                itemRender={Item}
                                dropDownButtonTemplate="conditionalIcon"
                                onSelectionChanged={selectionChanged}
                            >
                                <Template
                                    name="conditionalIcon"
                                    render={renderConditionalIcon}
                                />
                            </SelectBox>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 mt20xs mt20md  mt20lg">
                            <Button icon="refresh" text='Обновить' className='w-100' onClick={async(e) => refreshClick()} />
                        </div>
                        <div className={`col-lg-4 col-md-4 col-sm-4 mt20xs mt20md mt20lg text-lg-right w-100`}>
                            <Button icon='xlsfile' className='w-100' text='Выгрузить в Excel' onClick={loadFileEnergosfera} disabled={loadingFile || loading}/>
                            <LoadPanel visible={loadingFile} />
                        </div>
                    </div>
                    <LoadPanel visible={loading} />

                    <div id="chart-demo">
                        <div style={{textAlign: 'center'}}>
                            <span><b>{title}</b></span>
                        </div>
                        <Chart
                            dataSource={data}
                        >
                            <Size height={420}/>
                            <CommonSeriesSettings
                                argumentField="format_time"
                                type={chbBarLine}
                                hoverMode="includePoints"
                            >
                                <Point visible={false} hoverMode="allArgumentPoints" />
                            </CommonSeriesSettings>
                            <ValueAxis position="left">
                                <Title text="КВт/ч" />
                            </ValueAxis>
                            {
                                source.map((item) => {
                                    return <Series
                                        key={item.value}
                                        valueField={item.value}
                                        name={item.name}
                                    />
                                })
                            }
                            <Export enabled={true} />
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                hoverMode="excludePoints"
                            />
                            <LoadingIndicator enabled={true} />
                            <Tooltip
                                enabled={true}
                                shared={true}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                        <span><b>Время московское</b></span>
                    </div>
                </div>
            </ScrollView>
        </Popup>
    )

    function customizeTooltip(outInfo) {
        return {
            html: `
            <div style="z-index: 10000; position: relative">
                <div class="tooltip-body">
                    <div class="series-name">
                        <span class='top-series-name'>Время: ${outInfo.argumentText}</span>
                    </div>
                    <div class="value-text">
                        <span class='top-series-value'>КВт/ч: ${outInfo.originalValue}</span>
                    </div>
                </div>
            </div>`,
        };
    }

    function Item({ IconSrc, name }) {
        return (
            <div className="custom-item">
                <FontAwesomeIcon className="icon icon-header-h3 main-color main-color-light" icon={IconSrc} />
                <div className="product-name">{name}</div>
            </div>
        );
    }

    function ConditionalIcon({ value }) {
        if (value) {
            return (
                <FontAwesomeIcon className="main-color main-color-light" icon={value.IconSrc} />
            );
        }
        return <div className="dx-dropdowneditor-icon"></div>;
    }
}

export default EnergosferaChart
