import PivotGrid, {Export, FieldChooser,} from 'devextreme-react/pivot-grid'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'
import {useAuth} from '../../contexts/auth'
import {getCurrentPeriod, getDogovorCard} from '../../api/dogovor'
import React, {useEffect, useState} from 'react'
import notify from 'devextreme/ui/notify'
import LoadPanel from 'devextreme-react/load-panel'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard} from "@fortawesome/free-solid-svg-icons";
import {verifyAccess} from '../../utils/admin.utils'
import AccessDenied from '../../components/access-denied/access-denied'
import DateBox from 'devextreme-react/date-box'
import {getFirstDayOfMounthFix, getLastDayOfMounth} from '../../utils/date.utils'
//import { Export } from 'devextreme-react/data-grid'
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
const Card = () => {

    const {selectedDog} = useAuth()
    const [data, setData] = useState([])
    const [saldoInfo, setSaldoInfo] = useState([])
    const [loading, setLoading] = useState(false)
    const [visiblePage, setVisiblePage] = useState(true)
    const [periodStart, setPeriodStart] = useState(new Date())
    const [periodEnd, setPeriodEnd] = useState(new Date())

    useEffect(() => {
        const pageRoles = [3]
        if (!verifyAccess(pageRoles, selectedDog)) {
            setVisiblePage(false)
            return
        }
        (async function(){
            setVisiblePage(true)
            setLoading(true)
            const periodRes = await getCurrentPeriod(selectedDog.kodDog)
            if (periodRes.isOk) {
                setPeriodEnd(periodRes.data.period)
                const end = new Date(periodRes.data.period)
                const start = new Date(end.getFullYear()-1, end.getMonth(), end.getDay())
                setPeriodStart(start)

                const res = await getDogovorCard(selectedDog.kodDog,start,getLastDayOfMounth(end, false))
                if (res.isOk) {
                    setData(res.data.rows)
                    setSaldoInfo(res.data.years)
                } else notify(res.message,'error',5000)
            }
            setLoading(false)
        })()
    },[selectedDog])

    const onStartChanged = async (e) => {
        setPeriodStart(e)
        await updateCard(new Date(e), new Date(periodEnd))
    }

    const onEndChanged = async (e) => {
        setPeriodEnd(e)
        await updateCard(periodStart, new Date(e))
    }

    async function updateCard(start, end) {
        setLoading(true)
        //console.log(getFirstDayOfMounthFix(start),getLastDayOfMounth(end))
        const res = await getDogovorCard(selectedDog.kodDog,getFirstDayOfMounthFix(start),getLastDayOfMounth(end, false))
        if (res.isOk) {
            setData(res.data.rows)
            setSaldoInfo(res.data.years)
        } else notify(res.message,'error',5000)
        setLoading(false)
    }

    const calendarOptions = {
        applyValueMode: "useButtons",
        showDropDownButton:true,
        maxZoomLevel: 'year',
        minZoomLevel: 'century'
    }

    const dataSource = new PivotGridDataSource({
        fields: [
            // {
            //     dataField: 'sort_order',
            //     area: 'row',
            //     //filterValues: 'sort_order'
            //  },
            {
                caption: 'Вид начислений',
                width: 120,
                dataField: 'nach_name',
                area: 'row',
                sortingMethod: function (a,b) {
                    var resA = data.filter(obj => obj.nach_name === a.text )
                    var resB = data.filter(obj => obj.nach_name === b.text )
                    return (resA[0].sort_order < resB[0].sort_order) ? -1 :
                    (resA[0].sort_order === resB[0].sort_order) ? 0 : 1
                }
             },
             {
                dataField: 'period',
                dataType: 'date',
                area: 'column',
                groupInterval: 'year',
            },
            {
                dataField: 'period',
                dataType: 'date',
                area: 'column',
                groupInterval: 'month',
            },
            {
                caption: 'Сумма',
                dataField: 'total',
                dataType: 'number',
                summaryType: 'sum',
                format: {
                    currency: 'RUB',
                    precision: '2',
                    type: 'currency'
                },
                area: 'data',
            }
        ],
        store: data,
    })

    // const currencyFormatter = new Intl.NumberFormat(
    //     'ru-RU', {
    //       style: 'currency',
    //       currency: 'RUB',
    //       minimumFractionDigits: 0,
    //     },
    // )

    // const calOptions = {
    //     maxZoomLevel: 'year',
    //     minZoomLevel: 'year'
    // }

    //открыть последний год по умолчанию
    var c = 1
    function openLastYear() {
        if (c === 1) {
            let years = getYears(data)
            if (years.length > 0) dataSource.expandHeaderItem('column',[years[years.length - 1]])
            c++
        }
    }

    function getYears(data){
        let years = []
        for (let i=0; i<data.length; i++) {
            const period = data[i].period.slice(0,4)
            if (!years.includes(parseInt(period))) years.push(parseInt(period))
        }
        years = years.sort((a, b) => a - b)
        return years
    }

    function onCellPrepared(e){
        if (e.area === 'data' && e.cell.rowPath[0] === "Начальное сальдо" && e.cell.columnPath.length === 1){
            e.cellElement.innerText = saldoInfo.find(r => r.year === e.cell.columnPath[0]).saldo_start.toLocaleString('ru',{style:"currency", currency:"RUB"})
        }  else if (e.area === 'data' && e.cell.rowPath[0] === "Конечное сальдо" && e.cell.columnPath.length === 1){
            e.cellElement.innerText = saldoInfo.find(r => r.year === e.cell.columnPath[0]).saldo_end.toLocaleString('ru',{style:"currency", currency:"RUB"})
        }
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Лицевая карта');

        exportPivotGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), "Лицевая карта " + selectedDog.ndog + '.xlsx');
            });
        });
    };

    return (
        <>{visiblePage ?
        <div className={'content-block dx-card responsive-paddings big-input'}>
            <FontAwesomeIcon className="icon-header-h3 main-color main-color-light" icon={faIdCard} />
            <h3><strong>Лицевая карта</strong></h3>
            <div className="row mt20 mb20">
                <div className={'col-sm-4 col-md-3 col-lg-2'} style={{minWidth: '200px'}}><DateBox labelMode={'floating'} label={'Период начала:'} calendarOptions={calendarOptions} value={periodStart} onValueChange={onStartChanged} displayFormat='monthAndYear'/></div>
                <div className={'col-sm-4 col-md-3 col-lg-2 mt20xs'} style={{minWidth: '200px'}}><DateBox labelMode={'floating'} label={'Период окончания:'} calendarOptions={calendarOptions} min={periodStart} value={periodEnd} onValueChange={onEndChanged} displayFormat='monthAndYear'/></div>
            </div>
            <PivotGrid
                id="pivotgrid"
                dataSource={dataSource}
                onContentReady={openLastYear}
                allowSortingBySummary={true}
                allowSorting={true}
                allowFiltering={true}
                showBorders={true}
                showColumnTotals={false}
                showColumnGrandTotals={false}
                showRowTotals={false}
                showRowGrandTotals={false}
                visible={!loading}
                onCellPrepared={onCellPrepared}
                onExporting={onExporting}
                //currencyFormatter={currencyFormatter}
                //ref={(ref) => { this.pivotGrid = ref.instance; }}
            >
                <FieldChooser enabled={false} height={400} />
                <Export fileName={"Лицевая карта "+selectedDog.ndog} enabled={true}  />
            </PivotGrid>
            <LoadPanel visible={loading} />
        </div>
        : <AccessDenied/>}</>
    )
}

export default Card



